import React from "react"
import { FilterCircle } from "./Circle"
import { FilterStrike } from "./Strike"

import Spacer from "../util/Spacer"

const Filters = ({
  productTypes,
  allColors,
  allSizes,
  handleColor,
  handleSize,
  handleType,
  newType,
  newSize,
  newColor,
  resetFilter,
  HexColors,
}) => {
  var types = []
  var colors = []
  var sizes = []
  productTypes.forEach(type => {
    types.push({
      name: "type",
      value: type,
    })
  })
  allColors.forEach(type => {
    colors.push({
      name: "color",
      value: type,
    })
  })

  // console.log(colors)
  allSizes.forEach(type => {
    sizes.push({
      name: "size",
      value: type,
    })
  })

  function sliceHalf(array) {
    const half = Math.ceil(array.length / 2)
    const firstHalf = array.slice(0, half)
    const secondHalf = array.slice(half - array.length)
    return {
      firstHalf,
      secondHalf,
    }
  }

  const slicedColor = sliceHalf(colors)
  const slicedType = sliceHalf(types)
  const slicedSize = sliceHalf(sizes)

  let arr = [
    slicedType.firstHalf.length,
    slicedType.secondHalf.length,
    slicedColor.firstHalf.length,
    slicedColor.secondHalf.length,
    slicedSize.firstHalf.length,
    slicedSize.secondHalf.length,
  ]

  let largestNum = arr.reduce(function (accumulatedValue, currentValue) {
    return Math.max(accumulatedValue, currentValue)
  })

  const fillArray = array => {
    var fillArr = []
    if (array.length < largestNum) {
      for (let index = 0; index < largestNum; index++) {
        if (array[index]) {
          fillArr.push(array[index])
        } else {
          fillArr.push("")
        }
      }
    } else {
      fillArr = array
    }
    return fillArr
  }

  const filterGroups = [
    fillArray(slicedType.firstHalf),
    fillArray(slicedType.secondHalf),
    fillArray(slicedColor.firstHalf),
    fillArray(slicedColor.secondHalf),
    fillArray(slicedSize.firstHalf),
    fillArray(slicedSize.secondHalf),
    [...Array(largestNum).keys()],
  ]
  // console.log(fillArray(slicedType.firstHalf))
  return (
    <div className="absolute w-full transition-transform duration-300 transform bg-white lg:px-1 ">
      <nav className="relative grid items-start grid-cols-7 text-gray-300 border-b border-gray-100 ">
        {filterGroups.map((groups, index) => {
          return (
            <div
              className="border-gray-100 divide-y divide-gray-100 "
              key={index}
            >
              {groups.map((filter, index) => {
                const hex = HexColors.find(hex => hex.color === filter.value)
                const hexcode =
                  hex?.hexcode !== undefined ? hex?.hexcode : "#000000"
                return (
                  <span className="block" key={index}>
                    {filter.value ? (
                      (filter.name === "type" && (
                        <FilterStrike
                          title={filter.value}
                          onClick={() => handleType(filter.value)}
                          className={
                            newType === filter.value &&
                            `text-black line-through`
                          }
                          active={newType === filter.value ? true : false}
                        />
                      )) ||
                      (filter.name === "color" && (
                        <FilterCircle
                          title={filter.value}
                          onClick={() => handleColor(filter.value)}
                          className={
                            newColor === filter.value && `text-black text-left`
                          }
                          border={hexcode === "#ffffff" || hexcode === "#FFFFFF" ? true : false}
                          active={newColor === filter.value ? true : false}
                          hexcode={hexcode}
                        />
                      )) ||
                      (filter.name === "size" && (
                        <FilterStrike
                          title={filter.value}
                          onClick={() => handleSize(filter.value)}
                          className={
                            newSize === filter.value &&
                            `text-black line-through`
                          }
                          active={newSize === filter.value ? true : false}
                        />
                      ))
                    ) : (
                      <Spacer />
                    )}
                  </span>
                )
              })}
            </div>
          )
        })}

        <div className="absolute bottom-0 right-0 flex items-center transition duration-300 hover:text-black h-30px mr-20px">
          <button className="hover:line-through" onClick={() => resetFilter()}>
            Clear Filters
          </button>
        </div>
      </nav>
    </div>
  )
}

export default Filters
