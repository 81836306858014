import React from "react"
import { Link } from "gatsby"
// import { getImage } from "gatsby-plugin-image"
import ProductMeta from "../product/ProductMeta"
import ProductPrice from "../product/ProductPrice"
// import UtilImage from "../util/UtilImage"
// import { cell } from "./productCardShop.module.scss"

import { StoreContext } from "../../context/store-context"
import { currencyHook } from "../../utils/currencyHook"
import { FetchHook } from "../../utils/fetchHook"
const ProductCardShop = ({
  product,
  productId,
  sale,
  collaboration,
  style,
  settings,
  slug,
  title,
  minPrice,
}) => {
  // const imageOne = getImage(product.images[0].localFile)
  // const imageTwo = getImage(product.images[1].localFile)

  // console.log(settings)
  const { currencyCode } = React.useContext(StoreContext)

  const { fetchedProduct, fetchedImages } = FetchHook(productId)

  var price
  var compareAt
  var oos
  var imageOne
  var imageTwo
  // console.log(collaboration)
  if (fetchedProduct) {
    const fallbackPrices = [
      {
        price: {
          currencyCode: "GBP",
          amount: minPrice,
        },
      },
    ]
    const presentmentPrices = fetchedProduct?.variants[0]?.presentmentPrices

    const { currencyConversion } = currencyHook(
      presentmentPrices ? presentmentPrices : fallbackPrices
    )
    price = currencyConversion(minPrice, currencyCode)

    compareAt = currencyConversion(
      fetchedProduct.variants[0].compareAtPrice,
      currencyCode
    )
    oos = !fetchedProduct.availableForSale

    // console.log(product.images[0].asset.url)
    imageOne = product.cardImages?.image
      ? product?.cardImages?.image?.asset?.url
      : fetchedImages !== null
      ? fetchedImages[0]?.src
      : "https://dummyimage.com/600x400/ffffff/a6a6a6"
    imageTwo = product.cardImages?.hoverImage
      ? product?.cardImages?.hoverImage?.asset?.url
      : fetchedImages && fetchedImages.length >= 2
      ? fetchedImages[1]?.src
      : null
  }

  const cardTextColour = product?.cardTextColour?.hex
  return (
    <div
      className={`relative overflow-hidden border-b border-t -mb-px md:border-none flex flex-col md:-mr-px  cell justify-stretch md:aspect-w-4 md:aspect-h-5`}
      style={style}
    >
      <Link
        to={`/store/${slug}`}
        className="flex flex-col w-full h-full "
        style={{ backgroundColor: `${settings.hex}` }}
      >
        <ProductMeta
          cardTextColour={cardTextColour}
          oos={oos}
          collaboration={collaboration && collaboration.store.title}
          sale={sale}
          className="absolute leading-none text-right pointer-events-none p-15px md:p-20px group md:relative"
        />
        <figure className="relative flex items-center justify-center w-full border-b border-gray-200 group md:border-none md:absolute aspect-w-4 aspect-h-5">
          {imageOne && (
            <img
              src={imageOne}
              alt="product "
              className={`top-0 left-0 object-contain transition-opacity duration-300 w-full h-full ${
                imageTwo ? "md:group-hover:opacity-0" : ""
              } `}
            />
          )}
          {imageTwo && (
            <img
              src={imageTwo}
              alt="product"
              className="absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-300 opacity-0 md:group-hover:opacity-100"
            />
          )}
        </figure>
        <footer
          className={`relative z-10 hidden md:flex flex-col md:flex-row bg-white md:bg-transparent md:items-center md:justify-between py-10px px-15px md:p-20px leading-none md:mt-auto md:flex-initial h-full md:h-auto ${
            oos ? "text-gray-300" : ""
          }`}
        >
          <span className="mb-px" style={{ color: cardTextColour }}>
            {title}
          </span>
          <ProductPrice
            price={price}
            compareAt={compareAt}
            sale={sale}
            cardTextColour={cardTextColour}
          />
        </footer>
        <footer
          className={`relative z-10 flex md:hidden flex-col md:flex-row bg-white md:bg-transparent md:items-center md:justify-between py-10px px-15px md:p-20px leading-none md:mt-auto md:flex-initial h-full md:h-auto ${
            oos ? "text-gray-300" : ""
          }`}
        >
          <span className="mb-px">{title}</span>
          <ProductPrice price={price} compareAt={compareAt} sale={sale} />
        </footer>
      </Link>
    </div>
  )
}

export default ProductCardShop
