import React from "react"

const Search = ({ query, setQuery }) => {
  return (
    <nav className="sticky top-0 z-20 block text-white group md:hidden">
      <div className="bg-blackgroup">
        <input
          className="w-full leading-none text-white placeholder-white bg-black rounded-none h-45px padding wrap placeholder:opacity-100 focus:outline-none"
          id="search"
          type="text"
          placeholder="Search"
          value={query}
          onChange={e => {
            setQuery(e.target.value)
          }}
        />
      </div>
    </nav>
  )
}

export default Search
