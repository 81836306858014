import React from "react"

export function FilterStrike({ title, className, onClick, active }) {
  return (
    <button
      onClick={onClick}
      className={`w-full fixed-btn flex items-center leading-none transition duration-300 capitalize line-link hover:text-black group ${className}  `}
    >
      <span className={active ? "selected" : ""}>{title}</span>
    </button>
  )
}
