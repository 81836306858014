import * as React from "react"
import { graphql } from "gatsby"
import { CSSTransition } from "react-transition-group"

import Filters from "../../components/filters/Filters"
import Seo from "../../components/seo/seo"
import "./store.css"
import ButtonArrow from "../../components/button/ButtonArrow"
import ProductAd from "../../components/product/ProductAd"

import ProductCardShop from "../../components/product/ProductCardShop"
// import TextTitle from "../../components/section/TextTitle"
import { FilterHooks } from "../../utils/filterHooks"
import { globalHistory } from "@reach/router"
import { useLunr } from "react-lunr"

import Search from "../../components/search/Search"

// import { StoreContext } from "../../context/store-context"
export default function Products({ data, location }) {
  const {
    // products,
    // options,
    allSanityStore,
    allSanitySettings,
    allSanePro,
    sanityOptions,
    localSearchProducts,
  } = data

  const index = localSearchProducts.index
  const store = localSearchProducts.store

  const [query, setQuery] = React.useState("")

  const results = useLunr(query, index, store)
  // console.log(results)
  const campaigns = allSanityStore.nodes[0].campaigns
  const settings = allSanitySettings.nodes[0].cardColour
  const saneProducts = allSanePro.nodes
  const colorOptions = sanityOptions.nodes.map(nod =>
    nod?.hexcodeImage.length > 0
      ? nod?.hexcodeImage
      : [{ hexcode: "#000000", color: "None" }]
  )

  const HexColors = colorOptions.concat.apply([], colorOptions)

  const search = location.search
  const urlSearchParams = new URLSearchParams(search)
  const params = Object.fromEntries(urlSearchParams.entries())

  const { type, color, size } = params

  const {
    allProductTypes,
    allSizes,
    allColors,
    handleType,
    handleSize,
    handleColor,
    filterProducts,
    resetFilter,
    newType,
    newSize,
    newColor,
  } = FilterHooks(saneProducts, sanityOptions, type, color, size, search)

  const [showFilters, setFilter] = React.useState(false)

  // console.log(results)
  const toggleFilter = React.useCallback(value => {
    setFilter(value)
  }, [])

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") toggleFilter(false)
    })
  }, [toggleFilter])

  return (
    <>
      <Seo title="All Products" />
      <div>
        <nav className="sticky top-0 z-20 hidden text-white pt-45px md:pt-30px -mt-45px md:-mt-30px group lg:block">
          <div className="bg-black pointer-events-auto group md:-mt-px">
            <div className="relative top-0 z-40 flex items-center justify-between leading-none bg-black padding h-45px md:h-30px global-header wrap">
              <ButtonArrow
                title="Filters"
                click="true"
                active={showFilters}
                onClick={() => {
                  toggleFilter(!showFilters)
                }}
              ></ButtonArrow>
              {showFilters && (
                <button
                  className="line-link"
                  onClick={() => {
                    toggleFilter(false)
                  }}
                >
                  <span>Close</span>
                </button>
              )}
            </div>

            <CSSTransition
              timeout={500}
              classNames="slideDown"
              in={showFilters}
              unmountOnExit
            >
              <Filters
                productTypes={allProductTypes}
                allSizes={allSizes}
                allColors={allColors}
                handleColor={handleColor}
                handleSize={handleSize}
                handleType={handleType}
                newType={newType}
                newSize={newSize}
                newColor={newColor}
                resetFilter={resetFilter}
                HexColors={HexColors}
              />
            </CSSTransition>
          </div>
        </nav>

        <Search query={query} setQuery={setQuery} />
        <div className="hidden w-full pb-px overflow-hidden lg:block">
          <div className="grid grid-cols-2 lg:grid-cols-4 -mt-0.5">
            {filterProducts.length > 4 &&
              campaigns.map(campaign => {
                return (
                  <ProductAd
                    campaign={campaign}
                    settings={settings}
                    key={campaign._key}
                  ></ProductAd>
                )
              })}
            {filterProducts.length ? (
              filterProducts.map((product, index) => {
                const sale = product.store?.variants[0]?.store?.compareAtPrice
                const featuredTag = product.featuredTag
                return (
                  <ProductCardShop
                    style={{ order: `${index + 1}` }}
                    product={product}
                    key={product._id}
                    productId={product.store.id}
                    slug={product.store.slug.current}
                    title={product.store.title}
                    sale={sale ? true : false}
                    minPrice={product.store.priceRange.minVariantPrice}
                    collaboration={featuredTag ? featuredTag : ""}
                    settings={settings}
                  ></ProductCardShop>
                )
              })
            ) : (
              <div className="block col-span-4 wrap py-2.5 h-screen text-3xl">
                0 products
              </div>
            )}
          </div>
        </div>
        <div className="block w-full pb-px overflow-hidden lg:hidden">
          <div className="grid grid-cols-2 lg:grid-cols-4 -mt-0.5">
            {results.length > 4
              ? campaigns.map(campaign => {
                  return (
                    <ProductAd
                      campaign={campaign}
                      settings={settings}
                      key={campaign._key}
                    ></ProductAd>
                  )
                })
              : query === "" &&
                filterProducts.length > 4 &&
                campaigns.map(campaign => {
                  return (
                    <ProductAd
                      campaign={campaign}
                      settings={settings}
                      key={campaign._key}
                    ></ProductAd>
                  )
                })}

            {results.length ? (
              results.map((product, index) => {
                const featuredTag = product.featuredTag
                return (
                  <ProductCardShop
                    style={{ order: `${index + 1}` }}
                    product={product}
                    key={product.id}
                    productId={product.productId}
                    slug={product.path}
                    title={product.title}
                    sale={product.sale ? true : false}
                    minPrice={product.price}
                    settings={settings}
                    collaboration={featuredTag ? featuredTag : ""}
                  ></ProductCardShop>
                )
              })
            ) : query === "" ? (
              filterProducts.map((product, index) => {
                const sale = product.store?.variants[0]?.store?.compareAtPrice
                const featuredTag = product.featuredTag
                return (
                  <ProductCardShop
                    settings={settings}
                    style={{ order: `${index + 1}` }}
                    product={product}
                    key={product._id}
                    productId={product.store.id}
                    slug={product.store.slug.current}
                    title={product.store.title}
                    sale={sale ? true : false}
                    minPrice={product.store.priceRange.minVariantPrice}
                    collaboration={featuredTag ? featuredTag : ""}
                  ></ProductCardShop>
                )
              })
            ) : (
              <div className="block col-span-4 wrap py-2.5 h-screen text-3xl">
                0 products
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allSanePro: allSanityProduct(
      filter: {
        hide: { eq: false }
        store: { isDeleted: { eq: false }, status: { eq: "active" } }
      }
      sort: { order: ASC, fields: orderRank }
    ) {
      nodes {
        ...SaneShop
      }
    }
    options: allShopifyProductVariant {
      nodes {
        selectedOptions {
          value
          name
        }
      }
    }

    sanityOptions: allSanityProduct(
      filter: {
        hide: { eq: false }
        store: { isDeleted: { eq: false }, status: { eq: "active" } }
      }
      sort: { order: ASC, fields: orderRank }
    ) {
      nodes {
        hexcodeImage {
          hexcode
          color
        }
        store {
          options {
            values
            name
          }
        }
      }
    }

    allSanityStore {
      nodes {
        campaigns {
          _key
          image {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
              url
            }
          }
          position
          mobilePosition
          show {
            desktop
            mobile
          }
          span
        }
      }
    }
    allSanitySettings {
      nodes {
        cardColour {
          hex
        }
      }
    }

    localSearchProducts {
      index
      store
    }
  }
`
