import React from "react"

export function FilterCircle({
  title,
  className,
  onClick,
  active,
  hexcode,
  border,
}) {
  return (
    <button
      onClick={onClick}
      className={`w-full line-link flex items-center fixed-btn space-x-15px md:space-x-20px leading-none transition duration-300 capitalize  hover:text-black group ${className} `}
    >
      <svg
        className={`w-9px h-9px ${border ? "stroke-current" : ""}`}
        viewBox="0 0 20 20"
        fill={hexcode}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9.5" />
      </svg>

      <span className={`truncate ${active ? "selected" : ""}`}>{title}</span>
    </button>
  )
}
