import { useEffect, useState } from "react"
import { navigate } from "gatsby-link"
export function FilterHooks(
  allProducts,
  allOptions,
  type,
  color,
  size,
  search
) {
  const productTypes = allProducts.map(opt =>
    opt.store.productType ? opt.store.productType : ""
  )

  // console.log(productTypes)
  const sizeOptions = allOptions.nodes.map(nod => {
    const getSize = nod.store.options.find(opt => opt.name === "Size")
    return getSize ? getSize.values : ""
  })
  const colorOptions = allOptions.nodes.map(nod => {
    const getSize = nod.store.options.find(opt => opt.name === "Color")
    return getSize ? getSize.values : ""
  })

  // console.log(colorOptions)
  const mergeOptions = array => array.concat.apply([], array)

  const mergedType = mergeOptions(productTypes)
  const mergedSize = mergeOptions(sizeOptions)
  const mergedColor = mergeOptions(colorOptions)
  // console.log(mergedSize)
  // var myColor = []
  // var mySize = []

  // mergedColor.forEach(type => {
  //   myColor.push(type.value)
  // })
  // mergedSize.forEach(type => {
  //   mySize.push(type.value)
  // })
  const option = mergedType.filter(String)
  const option1 = mergedSize.filter(String)
  const option2 = mergedColor.filter(String)
  // console.log(option)

  const [filterProducts, setFilterProducts] = useState([])
  const [newType, setType] = useState(type)
  const [newSize, setSize] = useState(size)
  const [newColor, setColor] = useState(color)
  const [filtering, setFiltering] = useState(false)

  let allProductTypes = [...new Set(option)]

  let allSizes = [...new Set(option1)]
  let allColors = [...new Set(option2)]

  // console.log(allSizes)
  useEffect(() => {
    const operation = (list1, list2, isUnion = false) =>
      list1.filter(a => isUnion === list2.some(b => a._id === b._id))
    const inBoth = (list1, list2) => operation(list1, list2, true)
    let arrays = []
    let array1 = allProducts.filter(p => p.store.productType === newType)

    let array2 = allProducts.filter(p =>
      p.store.variants.some(opt => opt.store.title.includes(newSize))
    )

    let array3 = allProducts.filter(p =>
      p.store.variants.some(opt => opt.store.title.includes(newColor))
    )
    // console.log(allProducts.map(p => p.store.title))

    if (newType && newSize && newColor) {
      let arr1 = inBoth(array1, array2)
      let arr2 = inBoth(arr1, array3)
      arrays = arr2
    } else if (newType && newSize) {
      let arr1 = inBoth(array1, array2)
      arrays = arr1
    } else if (newType && newColor) {
      let arr1 = inBoth(array1, array3)
      arrays = arr1
    } else if (newSize && newColor) {
      let arr1 = inBoth(array2, array3)
      arrays = arr1
    } else if (newType) {
      arrays = array1
    } else if (newSize) {
      arrays = array2
    } else if (newColor) {
      arrays = array3
    } else {
      arrays = []
    }

    if (!filtering && search === "") {
      arrays = allProducts
    }
    let newArray = [...new Set(arrays)]

    setFilterProducts(newArray)
  }, [newType, newSize, newColor, allProducts, filtering, search])

  const setParams = (type, color, size) => {
    navigate(
      `${type ? `?type=${type}` : "?"}${color ? `&color=${color}` : ""}${
        size ? `&size=${size}` : ""
      } `
    )
  }
  const handleType = value => {
    setParams(value, newColor, newSize)
    setFiltering(true)
    setType(value)
  }
  const handleSize = value => {
    setParams(newType, newColor, value)

    setFiltering(true)
    setSize(value)
  }
  const handleColor = value => {
    setParams(newType, value, newSize)

    setFiltering(true)
    setColor(value)
  }
  const resetFilter = () => {
    setFiltering(false)
    setType("")
    setColor("")
    setSize("")
    setParams("")
    navigate("/store")
  }
  return {
    allProductTypes,
    allSizes,
    allColors,
    handleType,
    handleSize,
    handleColor,
    filterProducts,
    resetFilter,
    newType,
    newSize,
    newColor,
    filtering,
  }
}
