import React from "react"
import UtilImage from "../util/UtilImage"
import { cell } from "../product/productCard.module.scss"
import { useMediaQuery } from "react-responsive"
const ProductAd = ({ campaign, settings }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 })
  const desktop = campaign?.show?.desktop
  const mobile = campaign?.show?.mobile
  return (
    <article
      style={{
        order: `${
          isTabletOrMobile ? campaign?.mobilePosition : campaign?.position
        }`,
        backgroundColor: `${settings.hex}`,
      }}
      className={`relative   ${
        isTabletOrMobile
          ? mobile
            ? "flex md:hidden"
            : "hidden"
          : desktop
          ? "hidden md:flex"
          : "hidden"
      } flex-col -mb-px -mr-px ${cell} justify-stretch col-span-2  ${
        campaign.span ? `lg:col-span-${campaign.span}` : "lg:col-span-1"
      }`}
    >
      <div
        className="flex flex-col w-full h-full md:p-8px group"
        style={{ backgroundColor: `${settings.hex}` }}
      >
        <div className="relative w-full h-full">
          {isTabletOrMobile ? (
            <img src={campaign?.image?.asset?.url} alt="ads" />
          ) : (
            <UtilImage
              image={campaign?.image?.asset?.gatsbyImageData}
              className="absolute inset-0 h-full picture-cover"
            ></UtilImage>
          )}
        </div>
      </div>
    </article>
  )
}

export default ProductAd
